<template>
  <header>
    <div class="program-icon">
      <a href="/"> <img src="@/assets/logo.png" alt="icon" /></a>
    </div>
    <div class="program-title">
      <a href="/"> <h2 class="program-title-word">SSAFY 출결 생성기</h2></a>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style scoped>
a {
  text-decoration: none;
  color: black;
}

img {
  width: 250px;
}

.program-icon {
  margin: 3rem 0;
}
</style>
